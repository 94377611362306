:root {
  --secondary-color: #f50057;
  --softskills-color: #008B69;
}

/* --------------------------------------------- */
/* ------------------ BALISES ------------------ */
/* --------------------------------------------- */

html, body {
  min-height: 100%;
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  height: 100%;
}

p {
  font-size: 14px;
}

span {
  font-size: 14px;
  overflow-wrap: break-word;
}

button {
  outline: none !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ---------------------------------------------------------- */
/* - Overwrite the default scrollbar to keep always visible - */
/* ---------------------------------------------------------- */

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* ---------------------------------------------------------- */
/* ----------------- Overwrite DRAFT JS --------------------- */
/* ---------------------------------------------------------- */

.public-DraftEditorPlaceholder-root {
  z-index: 0 !important;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.DraftEditor-editorContainer .disabled {
  background-color:rgb(116, 116, 116);
  border-left: .1px solid transparent;
  position: relative;
  z-index: 0 !important;
}

/* --------------------------------------------- */
/* ------------------ CLASSES ------------------ */
/* --------------------------------------------- */

/* ------------------ COLORS ------------------ */
.softskills-bg-color {
  background-color: var(--softskills-color);
}

/* ------------------ LAYOUT ------------------ */
.app {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100%;
  z-index: 3;
  background-color: rgba(60, 60, 60, 1);
  padding: 7px 25px;
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.footer {
  width: 100%;
  background-color: rgba(60, 60, 60, 1);
  color: white;
  box-shadow: 0 0px 10px 0px black;
}

/* ------------------ TOOLBAR ------------------ */
.toolbar {
  padding: 15px 25px;
}

@media (max-width: 960px) {
  .toolbar {
    padding: 15px;
  }
}

/* ------------------ CONTAINERS ------------------ */
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0;
  width: 100%;
  max-width: none;
  padding: 0;
}

@media (min-width: 1280px) {
  .container {
    margin-left: 255px;
    width: calc(100% - 255px);
  }
}

/* ------------------ FULL CONTAINER ------------------ */
.full-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 0px 25px;
}

@media (max-width: 960px) {
  .full-container {
    padding: 0px 15px;
    text-align: center;
  }
}

.full-container-content {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 400px;
  min-height: 400px;
}

/* ------------ HALF CONTAINER ------------ */
.half-container-content {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 200px;
  min-height: 200px;
}

/* ------------------ SIMPLE CONTAINER ------------------ */
.simple-container {
  padding: 0px 25px;
}

@media (max-width: 960px) {
  .simple-container {
    padding: 0px 15px;
    text-align: center;
  }
}

/* ------------------ SIDE MENU ------------------ */
.side-menu {
  top: 65px;
  bottom: 0;
  width: 255px;
  box-shadow: 8px 0px 5px -5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.side-menu-title {
  color: black;
  margin-left: 15px;
}

/* ------------------ SIDE DRAWER ------------------ */
.side-drawer {
  position: absolute !important;
  padding: 10px !important;
  color: white !important;
  outline: none !important;
  z-index: 300;
}

/* ------------------- PROFILE -------------------- */
.profile {
  min-height: 255px;
}

/* --------------------- MENU --------------------- */
.menu {
  flex: 1;
}

/* ------------------ BREADCRUMB ------------------ */
.breadcrumb {
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.1);
  min-height: 45px;
  display: inline-flex;
  padding: 0 15px;
  z-index: 200;
  border-radius: 0;
  margin: 0;
  flex-wrap: initial;
}

.breadcrumb-item {
  color: white;
}

/* -------------------- BADGE --------------------- */
.badge {
  border-radius: 4px;
  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

/* ---------------- LIST ITEM HOVER ---------------- */
.list-item-hover {
  cursor: pointer;
}

  .list-item-hover:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

/* -------------------- INPUT TITLE -------------------- */
.input-title {
  text-transform: uppercase;
  color: rgba(80, 80, 80, 1);
  font-size: 12px;
}

/* -------------------- INPUT FILTER -------------------- */
.input-filter {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-size: 14px;
  padding: 7.5px 10px;
}

/* ---------------- INPUT FILE ---------------- */
.div-input-file {
  display: flex;
  align-items: center;
}

.label-input-file {
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid lightgray;
  cursor: pointer;
  margin-bottom: 0px;
}

  .label-input-file:hover {
      border-color: #1976d2;
      color: #1976d2;
  }

.input-file {
  outline: none;
}

/* ------------------ GRID ------------------ */
.row {
  margin-right: 0;
  margin-left: 0;
}

.col {
  margin-top: auto;
  margin-bottom: auto;
}

/* ------------------ PROGRESS ------------------ */
.progress {
	background-color: #d8d8d8;
	border-radius: 4px;
  position: relative;
	height: 30px;
	width: 100%;
}

.progress-done {
	background-color: gray;
	box-shadow: 0 3px 3px -5px #F2709C, 0 2px 5px #F2709C;
	border-radius: 4px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}

/* ------------------ ERROR ------------------ */
.error-and-spinner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px;
}

/* ------------------ CHART ------------------ */
.chart {
  height: 350px;
  overflow: auto;
}

  .chart > div {
    min-width: 850px;
  }

/* ------------------ PANEL SUMMARY ------------------ */
.panel-summary {
  margin: 0 !important;
}

/* ------------------ LIST BUTTON ------------------ */
.list-btn {
  padding: 8px !important;
}

/* ------------------ BOX SHADOW ------------------ */
.box-shadow {
  box-shadow: 1px 3px 2px 2px rgba(0, 0, 0, 0.1);
}

/* ------------------ TD ------------------ */
.td:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}

/* ------------------ DIALOG TITLE ------------------ */
.dialog-title {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
}

/* ------------------ CERTIFICATION ------------------ */
.certificated {
  margin: auto 0;
}

.certificated:hover {
  color: var(--secondary-color);
}

/* ------------------ POSITIONING ------------------ */
.background-primary {
  background-color: #E0F0FB;
}

.background-secondary {
  background-color: #F0D4DE;
}

.background-linear {
  background: linear-gradient(to bottom left, #F0D4DE 50%, #E0F0FB 50%); 
}

/* ---------------------- LINK ---------------------- */
.link-container {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.link-edit {
  padding: 5px 15px;
  border-radius: 3px;
  flex: 1;
  cursor: pointer;
}

  .link-edit:hover {
    background-color: #AEDAFA;
    color: #3468FB;
  }

.link-delete {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  padding: 5px;
}

  .link-delete:hover {
    background-color: #FFBDAD;
    color: #DE350B;
  }

/* ---------------------- CALENDAR ---------------------- */
.calendar {
  cursor: pointer;
}

  .calendar:hover .month {
    color: white;
    background-color: gray;
  }

/* ---------------------- SESSION ---------------------- */
.session {
  cursor: pointer;
}

  .session:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

/* ------------------------- FAB ----------------------- */
.fab-container {
  width: fit-content;
  margin: 10px 0px;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: rgba(200, 200, 200, 0.5);
}

.fab {
  outline: none !important;
  width: 40px !important;
  height: 40px !important;
}

/* ----------------- BILLING MENU ------------------ */
.billing-menu {
  width: 100%;
  min-height: 200px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

  .billing-menu:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

    .billing-menu:hover .billing-menu-label {
      color: white;
    }

.billing-menu-container-icon {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.billing-menu-container-label {
  width: 100%;
  text-align: center;
  border-top: 1px solid gray;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.billing-menu-label {
  font-size: 20px;
}

/* -------------------- BILLING INFOS --------------------- */
.billing-infos {
  border: 1px solid lightgray;
  border-radius: 4px;
  width: fit-content !important;
}

/* ----------------- SOFT SKILL MENU ------------------ */
.soft-skill-menu {
  padding: 24px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

  .soft-skill-menu:hover {
    color: white;
    background-color: var(--softskills-color);
  }

/* ----------------- SOFT LEVEL ------------------ */
.soft-level-table {
  overflow-x: auto;
}

.soft-level-table table {
  min-width: 1000px;
}

.soft-level-td {
  border: 1px solid lightgray;
  width: 20%;
  padding: 10px;
}

.soft-level {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  padding: 4px;
  height: 160px;
  overflow: auto;
}

  .soft-level:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

.soft-level-definition {
  overflow: auto;
  margin-bottom: 0;
}

/* ------------------ EVALUATION RATING ------------------- */
.evaluation-rating-button {
  cursor: pointer;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  color: white;
  width: 100%;
  opacity: 0.6;
}

  .evaluation-rating-button:hover {
    opacity: 1;
  }

/* ---------------- TOOL CARD ---------------- */
.tool-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 340px;
  padding: 12px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch; 
}

/* ---------------- PAPER ---------------- */
.paper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 260px;
  padding: 12px;
  overflow: scroll;
}

/* ----------------- POSITION TABLE ------------------ */
.table-position .br {
  border-right: 1px solid lightgray;
}

.table-position .bt {
  border-bottom: 1px solid lightgray;
}

.table-position .br:last-child {
  border: none;
}

.table-position .bt:last-child {
  border: none;
}

/* --------------------------------------------- */
/* ------------- MEDIA MANAGEMENT -------------- */
/* --------------------------------------------- */

/* COL-XL */
@media (min-width: 1600px) {
  .input-file {
    font-size: 14px;
  }

  .breadcrumb-item, .side-menu-title {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .input-file {
    font-size: 14px;
  }

  .title, .side-menu-title, .breadcrumb-item {
    font-size: 16px;
  }
}

@media (max-width: 992px) {
  .container {
    margin-left: 0;
    width: 100%;
  }

  .form-control {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .md-text-align-center {
    text-align: center;
    justify-content: center;
  }

  .md-margin-auto {
    margin: auto !important;
  }

  .md-margin-bot {
    margin-bottom: 10px !important;
  }
}

/* COL-XS */
@media (max-width: 576px) {
  .form-control, .input-file {
    font-size: 12px;
  }

  .title, .breadcrumb-item, .side-menu-title {
    font-size: 14px;
  }
}

/* ----------------- CLEA MENU ------------------ */
.clea-menu {
  padding: 24px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

  .clea-menu:hover {
    color: white;
    background-color: #641c80;
  }

  ul .list-clea-competence-skillbase {
    background-color: #DCBDE9;
  }

  ul .list-clea-competence-skillbase:hover {
    color: white;
    background-color: #641c80;
  }

  .clea-icon-button {
    background: #641c80; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #853CA2 0%, #641c80 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #853CA2 0%,#641c80 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #853CA2 0%,#641c80 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c576e8', endColorstr='#641c80',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    margin: auto;
    height: 45px;
    width: 45px;  
    line-height: 45px;  
    font-size: 1.3em;
    font-weight: 400;
    border-radius: 50%;
    color: white;
    text-align: center;
    cursor: pointer;
    box-shadow:  2px 2px 5px gray;
  }

  .clea-icon-button:hover {
    background: #914FAC; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #A86EBF 0%, #914FAC 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #A86EBF 0%,#914FAC 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #A86EBF 0%,#914FAC 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c576e8', endColorstr='#641c80',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    margin: auto;
    height: 45px;
    width: 45px;  
    line-height: 45px;  
    font-size: 1.3em;
    font-weight: 400;
    border-radius: 50%;
    color: white;
    text-align: center;
    cursor: pointer;
    box-shadow:  2px 2px 5px gray;
  }
